<template>
  <iq-title> У вас нет доступных таймслотов </iq-title>
</template>

<script>
import IqTitle from '@/UI/typography/IqTitle'

export default {
  name: 'NoTimeSlots',
  components: {
    IqTitle,
  },
}
</script>

<style scoped></style>
