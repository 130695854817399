<template>
  <div v-loading="getLoading">
    <time-left v-if="runTimer && showTimer" />
    <iq-title
      v-if="runTimer && showTimer"
      style="color: #eb6626"
      :class="{ desktopHeader: !isMobile, mobileHeader: isMobile }"
    >
      Ожидают подтверждения:
    </iq-title>
    <div v-for="(item, i) in getSloatPriceData" :key="i">
      <time-slot-card
        :confirm-id="confirmIdArray.includes(item.timeslot_id)"
        :car-number="getCarNumber(item.timeslot_id)"
        :card="item"
        @submitedTimeslot="submitTimeslot(item.timeslot_id)"
        @routerPushTimeslot="routerPush(item.timeslot_id)"
        @openedDelete="openDelete(item.timeslot_id)"
        @open="opened"
      />
    </div>

    <iq-title :class="{ desktopHeader: !isMobile, mobileHeader: isMobile }">
      Подтвержденные тайм слоты:
    </iq-title>
    <no-time-slots v-if="timeslotsList.length === 0" />
    <div v-for="item in timeslotsList" :key="item.id">
      <time-slot-card
        :card="item"
        @open="opened(item)"
        @setNewTimeslotTime="setNewTime(item)"
      />
    </div>
    <div v-if="needPagination" class="more-button" @click="loadMoreTimeslots">
      Показать еще
    </div>
    <AlarmDeleteModal
      v-if="dialogFormVisible"
      :dialog-form-visible="dialogFormVisible"
      :dialog-data="dialogData"
      @close="dialogFormVisible = false"
    />
    <timeslot-shift-params
      :timeslot="shiftedTimeslot"
      @getTimeslot="getNewTimeslot"
    />
    <timeslot-shift-accept
      :params="acceptingNewTimeslot"
      @acceptTimeslot="acceptNewTimeslot"
      @declineTimeslot="dropNewTimeslot"
    />
    <timeslot-shift-reset @newTimeslot="resetTimeslotTime" />
  </div>
</template>

<script>
import { DATE_FORMAT, GET_TIME } from '@/constants/date'
import {
  DR_FETCH_TIMESLOT_LIST,
  DR_GET_FULL_FORM_WITH_AUTO,
  DR_GET_LOADINGS,
  DR_GET_RUN_TIMER,
  DR_GET_SLOAT_PRICE_DATA,
  DR_GET_TIMESLOT_LIST,
  DR_GET_TIMESLOT_PAGINATION,
  DR_POST_TIMESLOTS_SUBMIT,
  DR_REMOVE_TIMESLOT,
  DR_UPDATE_RUN_TIMER,
  DR_UPDATE_TIMESLOT_LIST,
} from '@/store/actions'
import { Message } from 'element-ui'
import {
  TIMESLOT_SHIFT_ACCEPT,
  TIMESLOT_SHIFT_PARAMS,
  TIMESLOT_SHIFT_RESET,
} from '@/constants/dialogs'
import { getIsAndroid, getTime } from '@/core'
import { mapActions, mapGetters } from 'vuex'
import { timeslotStatuses } from '@/views/manager/data/manager'
import API from '@/api/index'
import AlarmDeleteModal from './modals/AlarmDeleteModal'
import IqTitle from '@/UI/typography/IqTitle'
import NoTimeSlots from './components/NoTimeSlots'
import TimeLeft from '@/views/drivers-home/components/TimeLeft'
import TimeSlotCard from './components/TimeSlotCard'
import TimeslotShiftAccept from './dialogs/timeslotShift/TimeslotShiftAccept.vue'
import TimeslotShiftParams from './dialogs/timeslotShift/TimeslotShiftParams'
import TimeslotShiftReset from './dialogs/timeslotShift/TimeslotShiftReset'

export default {
  name: 'Timeslots',
  components: {
    TimeLeft,
    AlarmDeleteModal,
    TimeSlotCard,
    IqTitle,
    NoTimeSlots,
    TimeslotShiftAccept,
    TimeslotShiftParams,
    TimeslotShiftReset,
  },
  data() {
    return {
      confirmIdArray: [],
      GET_TIME,
      DATE_FORMAT,
      timeslotStatuses,
      dialogFormVisible: false,
      dialogData: null,
      currentPage: 1,
      // блок переноса слота
      isShiftConfirmating: false,
      shiftedTimeslot: null,
      acceptingNewTimeslot: null,
    }
  },
  computed: {
    ...mapGetters({
      getSlotPriceData: DR_GET_SLOAT_PRICE_DATA,
      timeslotsList: DR_GET_TIMESLOT_LIST,
      pageCount: DR_GET_TIMESLOT_PAGINATION,
      getLoading: DR_GET_LOADINGS,
      formData: DR_GET_FULL_FORM_WITH_AUTO,
      runTimer: DR_GET_RUN_TIMER,
    }),
    getSloatPriceData() {
      return this.getSlotPriceData.filter(slot => slot.timeslot !== null)
    },
    showTimer() {
      const activeSlot = this.getSloatPriceData.filter(item => {
        if (
          !item.timeslot?.deleted_at &&
          item['state_system'] !== 'time_submitted'
        ) {
          return true
        }
      })

      return activeSlot.length > 0
    },

    isAndroid() {
      return getIsAndroid()
    },

    needPagination() {
      return (
        this.timeslotsList.length && this.currentPage < Number(this.pageCount)
      )
    },
  },
  watch: {
    runTimer() {
      // закрытие модалки переноса таймслота по истечении времени
      if (!this.runTimer && this.isShiftConfirmating) {
        this.dropNewTimeslot()
      }
    },
  },

  methods: {
    ...mapActions({
      removeTimeslot: DR_REMOVE_TIMESLOT,
      submitTimeslots: DR_POST_TIMESLOTS_SUBMIT,
      fetchTimeSlotsList: DR_FETCH_TIMESLOT_LIST,
      updateTimeSlotsList: DR_UPDATE_TIMESLOT_LIST,
      addTimeslotsPage: DR_UPDATE_TIMESLOT_LIST,
      updateRunTime: DR_UPDATE_RUN_TIMER,
    }),
    opened(item) {
      if (item.truck_status === 'not_stated') {
        this.dialogFormVisible = true
        this.dialogData = item
      }
    },
    confirmSlot(timeslot_id) {
      const index = this.getSloatPriceData.findIndex(
        i => String(i.timeslot_id) === String(timeslot_id),
      )

      return (
        index !== -1 &&
        this.getSloatPriceData[index]['state_system'] === 'time_submitted' &&
        !this.getSloatPriceData[index].timeslot?.deleted_at
      )
    },
    getCarNumber(id) {
      if (this.formData.length > 0) {
        let timeslot = this.formData.filter(
          item => String(item.timeslot_id) === String(id),
        )

        if (timeslot.length > 0) {
          return ' Авто №' + timeslot[0].plate_truck
        }
      }

      return null
    },
    // удаление таймслота
    open(item) {
      this.dialogFormVisible = true
      this.dialogData = item
    },
    routerPush(id) {
      this.$router.push({ name: 'ConfirmAddAuto', params: { id } })
    },
    getTime,
    submitTimeslot(id) {
      this.formData.map(item => {
        if (item.timeslot_id === id) {
          this.submitTimeslots(item)
          localStorage.removeItem('get-slot-form')
        }
      })
    },

    openDelete(id) {
      this.$confirm(
        'Вы действительно хотите отказаться от тайм слота?',
        'Внимание!',
        {
          confirmButtonText: 'Да, хочу отказаться',
          cancelButtonText: 'Нет, не хочу',
          type: 'warning',
          customClass: 'confirm-modal',
          confirmButtonClass: 'confirmDelete',
        },
      )
        .then(() => {
          this.cancelSubmit(id)

          this.$message({
            type: 'danger',
            message: 'Вы отказались от тайм слота',
            customClass: 'driver-message',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Отказ от тайм слотов не подтвержден',
            customClass: 'driver-message',
          })
        })
    },
    cancelSubmit(id) {
      let deleteTimeslot

      this.getSloatPriceData.map(item => {
        if (item.timeslot_id === id) {
          deleteTimeslot = item
        }
      })
      deleteTimeslot.submit = 0
      this.submitTimeslots(deleteTimeslot)
    },
    // подгрузка страницы пагинации
    loadMoreTimeslots() {
      this.updateTimeSlotsList(++this.currentPage)
    },

    // БЛОК ПЕРЕНОСА ТАЙМСЛОТА
    // перенести тайм слот (нажатие кнопки Перенести)
    setNewTime(timeslot) {
      this.shiftedTimeslot = timeslot
      this.setDialog({ name: TIMESLOT_SHIFT_PARAMS, visible: true })
    },
    // запрос на наличие свободного таймслота
    getNewTimeslot(dataRequest) {
      dataRequest.timeslot_id = this.shiftedTimeslot.id
      API.drivers
        .postTimeslotMoveRequest(dataRequest)
        .then(response => {
          this.setDialog({ name: TIMESLOT_SHIFT_PARAMS, visible: false })

          if (response.data.timeslot) {
            this.setDialog({ name: TIMESLOT_SHIFT_ACCEPT, visible: true })
            this.updateRunTime(true)
            this.acceptingNewTimeslot = response.data.timeslot
            this.isShiftConfirmating = true
          } else {
            this.setDialog({ name: TIMESLOT_SHIFT_RESET, visible: true })
          }
        })
        .catch(error => console.log(error))
    },
    // подтверждение переноса таймслота
    acceptNewTimeslot() {
      let dataRequest = {
        timeslot_id: this.acceptingNewTimeslot.id,
        submit: 1,
        plate_truck: this.shiftedTimeslot.plate_truck,
        plate_trailer: this.shiftedTimeslot.plate_trailer,
        name: this.shiftedTimeslot.name,
        phone: this.shiftedTimeslot.phone,
        truck_type_code: this.shiftedTimeslot.truck_type_code,
      }

      API.drivers
        .postTimeslotsSubmit(dataRequest)
        .then(() => {
          this.currentPage = 1
          this.fetchTimeSlotsList()
          this.setDialog({ name: TIMESLOT_SHIFT_ACCEPT, visible: false })
          Message({
            type: 'success',
            message: 'Тайм слот успешно перенесен!',
            offset: 50,
          })
          this.shiftedTimeslot = null
        })
        .catch(error => console.log(error))
    },
    // отказаться от предложенного нового таймслота
    dropNewTimeslot() {
      this.setDialog({ name: TIMESLOT_SHIFT_ACCEPT, visible: false })
      this.acceptingNewTimeslot = null
      this.isShiftConfirmating = false
      this.shiftedTimeslot = null
      this.updateRunTime(false)
    },
    // выбор нового времени для переноса таймслота
    resetTimeslotTime() {
      this.setDialog({ name: TIMESLOT_SHIFT_RESET, visible: false })
      this.setDialog({ name: TIMESLOT_SHIFT_PARAMS, visible: true })
    },
  },
}
</script>

<style scoped lang="sass">
.more-button
  display: flex
  justify-content: center
  align-items: center
  width: 160px
  height: 28px
  margin: 32px auto 40px
  border-radius: 4px
  background: #F5F7FA
  color: #303133
  border: 1px solid #DCDFE6
  font-family: Roboto, sans-serif
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 22px
  cursor: pointer
</style>
